.qr-code-generator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color:rgb(12, 12, 12);
}

.custom-file-upload-content {
  display: inline-block;
  padding: 0.5rem 0.2rem;
  cursor: pointer;
  background-color: #f3b562;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
}

.custom-file-upload-content:hover {
  background-color: rgb(12, 12, 12);
  color: #f3b562;
  border: 1px solid #f3b562;
}

.file-name {
  margin-top: 8px;
  font-size: 14px;
  color: #f3b562;
  text-align: center;
}