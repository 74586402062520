/* Additional global styles */
body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  /* background-color: rgba(0, 0, 0, 0.596); */
}

.app-container {
  /* display: flex; */
  /* flex-direction: column; */
  max-width: 100%;
  margin: 0 auto !important;
  padding: 0 !important;
  /* background-color: rgb(12, 12, 12); */
  /* background-color: rgb(238, 238, 238); */
  /* background-color: rgba(0, 0, 0, 0.596); */
}
