/* Existing CSS for the sidebar and icons */

.sidebar {
  width: 5rem; /* Set initial width for closed sidebar */
  /* background-color: white; */
  background-color: rgb(12, 12, 12);
  transition: width 0.5s ease;
  overflow-x: scroll;
  height: 99vh;
  /* box-shadow: 10px 0px 0px 0px rgb(117, 117, 117); */
}

.sidebar.close {
  width: 5rem; /* Set initial width for closed sidebar */
  /* background-color: white; */
  background-color: rgb(12, 12, 12);
  transition: width 0.5s ease;
  overflow-x: scroll;
  box-shadow: 4px 8px 10px 4px rgb(34, 34, 34);
  /* height: 99vh; */
  /* height: auto !important; */
}

.sidebar.open {
  width: 15rem; /* Set expanded width for open sidebar */
  /* background-color: white; */
  background-color: rgb(12, 12, 12);
  transition: width 0.5s ease;
  overflow-x: scroll;
  box-shadow: 4px 8px 20px 4px rgb(34, 34, 34);
}

.sidebar-toggle {
  background-color: rgb(243, 181, 98);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar-toggle:hover {
  background-color: white;
  color: rgb(243, 181, 98);
}

.sidebar-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.sidebar-menu-item {
  color: rgb(243, 181, 98);
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

.sidebar-icons {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-icon {
  font-size: 2rem;
  margin-top: 40px;
  margin-bottom: 20px;
  color: rgb(243, 181, 98);
  position: relative;
}

/* Tooltip container */
.tooltip {
  display: none;
  position: absolute;
  bottom: 100%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  background-color: rgb(243, 181, 98);
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1;
}

.sidebar-icon:hover .tooltip {
  display: block;
}

.sidebar-icon:hover {
  color: rgb(238, 238, 238);
}

.sidebar-icon:hover .tooltip::after {
  content: attr(data-tooltip);
}

/* Add margin between icons */
.sidebar-icons li {
  margin-bottom: 10px;
}
