.footer {
  background-color: rgb(243, 181, 98);
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  /* overflow: hidden; */
  height: auto;
  position: fixed;
  bottom: 0;
  text-wrap: wrap;
}

.footer-title {
  color: rgb(12, 12, 12);
  margin: 0;
}
