.main-container-ls {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89.75vh;
  width: 100%;
  /* border: 200px solid red; */
}

.login-signup-container {
  width: 25%;
  /* max-width: 600px; */
  /* min-height: 350px; */
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgb(243, 181, 98);
  text-align: center;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); Add shadow for better visual separation */
  box-shadow: 5px 5px 5px rgb(184, 184, 184);
}

.form-toggle {
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(12, 12, 12);
  color: rgb(243, 181, 98);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

button:hover {
  background-color: white;
  color: rgb(243, 181, 98);
}

p {
  margin-top: 10px;
  font-size: 14px;
}

p > a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
}

p > a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1050px) {
  .login-signup-container {
    width: 60%;
    max-width: 600px;
    min-height: 350px;
  }
}

/* @media screen and (max-width: 1000px), (min-width: 501px) {
  .login-signup-container {
    width: 35%;
    max-width: 600px;
    min-height: 350px;
  }
} */
