.dashboard-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .dashboard-cards {
    flex-direction: column;
    align-items: center;
  }
}
.dashboard-cards {
  margin-top: 20px;
}

.dashboard-card {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.dashboard-card .ant-card-body {
  padding: 24px;
  text-align: center;
}

.dashboard-card .ant-statistic-title {
  font-weight: 500;
}
