.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.content-container {
  display: flex;
  width: 100%;
}

.main-content {
  flex-grow: 2;
  padding: 20px;
  text-align: center;
}

.main-heading {
  margin-top: 5rem;
  font-size: 24px;
  color: rgb(243, 181, 98);
  margin-bottom: 20px;
}

/* Add styles for Header */
.header {
  background-color: rgb(243, 181, 98);
  padding: 10px 20px;
}

.header-title {
  color: white;
  margin: 0;
}

/* Add styles for Sidebar */
.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  height: 800px;
  text-align: left;
}

.sidebar-menu-item {
  margin-top: 3rem;
  margin-bottom: 10px;
  cursor: pointer;
}

.sidebar-menu-item:hover {
  background-color: rgb(243, 181, 98);
  color: white;
  transition: all 0.5s ease-out;
  border-radius: 5px;
}

@media screen and (max-width: 1002px) {
  .sidebar {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: auto;
    padding: 20px 0px 5px 0px;
    box-shadow: 0px 0px 25px 0px rgb(243, 181, 98);
  }
  .content-container {
    flex-direction: column;
  }
  .sidebar-menu {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-around;
    align-items: center;
  }
  .sidebar-icons {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
  .sidebar-icon,
  .sidebar-menu-item {
    margin-top: 10px !important;
    margin-bottom: 0 !important;
    margin-left: 0.5rem;
  }
  .sidebar-content {
    display: flex;
  }
  .sidebar-toggle {
    width: 25%;
    margin: 0 auto;
  }
}
