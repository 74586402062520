.navbar {
  width: 100% !important;
  background-color: rgb(243, 181, 98);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar button {
  width: 10%;
  padding: 1rem;
  margin-right: 1rem;
  background-color: rgb(12, 12, 12);
  color: white;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar button:hover {
  background-color: white;
  color: rgb(243, 181, 98);
}

.logoDiv {
  width: 300px;
  padding: 5px;
  margin-left: 1rem;
  color: white;
  text-decoration: none;
  font-size: 17px;
}

.logo {
  width: 25%;
}

.button-text {
  display: inline;
  margin-left: 0.1rem;
}

/* Hide mobile icon and show desktop icon by default */
.mobile-icon {
  display: none;
}

@media screen and (max-width: 1002px) {
  .navbar button {
    display: block;
  }

  /* Hide desktop icon and show mobile icon on small screens */
  .desktop-icon {
    display: none;
  }

  .mobile-icon {
    display: inline;
  }

  .navbar button .button-text {
    display: none;
  }
}
